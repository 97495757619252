.flip-card-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flip-card {
  background-color: transparent;
  width: 100%;
  margin-right: 1px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.5s;
  animation: flipAnimation 3s infinite;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front1,
.flip-card-back1,
.flip-card-front2,
.flip-card-back2,
.flip-card-front3,
.flip-card-back3,
.flip-card-front4,
.flip-card-back4,
.flip-card-front5,
.flip-card-back5 {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.flip-card-front1 {
  background-color: black;
}

.flip-card-back1 {
  background-color: #e50000;
  transform: rotateY(180deg);
}

.flip-card-front2 {
  background-color: rgba(0, 0, 0, 0.8);
}

.flip-card-back2 {
  background-color: #ff8d00;
  transform: rotateY(180deg);
}

.flip-card-front3 {
  background-color: rgba(0, 0, 0, 0.6);
}

.flip-card-back3 {
  background-color: #ffee00;
  transform: rotateY(180deg);
}

.flip-card-front4 {
  background-color: rgba(0, 0, 0, 0.4);
}

.flip-card-back4 {
  background-color: #028121;
  transform: rotateY(180deg);
}

.flip-card-front5 {
  background-color: rgba(0, 0, 0, 0.2);
}

.flip-card-back5 {
  background-color: #770088;
  transform: rotateY(180deg);
}

@keyframes flipAnimation {
  0%,
  100% {
    transform: rotateY(0deg);
  }

  50% {
    transform: rotateY(180deg);
  }
}
