@tailwind base;
@tailwind components;
@tailwind utilities;

.font-link {
  font-family: 'Poppins', sans-serif;
  font-family: 'Saira', sans-serif;
}
.slider {
  width: 100%;
  height: 400px;
}
.slide,
.slide img {
  width: 100%;
  height: 500px;
}
.header {
  margin: 50px auto;
  text-align: center;
  font-size: 80px;
}
.empowerCriticalslide {
  opacity: 0.5;
  transform: scale(0.7);
  transition: 0.5s;
}
.empowerCriticalslide-active {
  opacity: 1;
  transform: scale(1);
}

.empowerCriticalslideblock {
  display: block;
  width: 100%;
  color: #000000;
  opacity: 0.6px;
}

/* for corner borders in career home page */

.corner-border {
  --b: 3px;
  /* border thickness*/
  --s: 60px;
  /* size of the corner*/
  --g: 14px;
  --c: #000000;

  padding: calc(var(--b) + var(--g));
  background-image: conic-gradient(
      from 90deg at top var(--b) left var(--b),
      #0000 25%,
      var(--c) 0
    ),
    conic-gradient(
      from -90deg at bottom var(--b) right var(--b),
      #0000 25%,
      var(--c) 0
    );
  background-position: var(--_p, 0%) var(--_p, 0%),
    calc(100% - var(--_p, 0%)) calc(100% - var(--_p, 0%));
  background-size: var(--s) var(--s);
  background-repeat: no-repeat;
  cursor: pointer;
  transition: background-position 0.3s var(--_i, 0.3s),
    background-size 0.3s calc(0.3s - var(--_i, 0.3s));
}

.circle-inner {
  position: relative;
}

.border-gradient-white-blue:before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border-radius: 100%;
  background: linear-gradient(to right, white, #0091cf);
  z-index: -1;
}
/* Page not found strips */

.strips {
  background: repeating-linear-gradient(
    -55deg,
    #222,
    #222 16px,
    #ffff00 20px,
    #ffff00 40px
  );
  animation: strips 100s infinite;
}
@keyframes strips {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
}

/* Anticlock motion in page not found */

.spin-anticlockwise {
  animation: anticlockwise 3s linear infinite;
}

@keyframes anticlockwise {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

/* Clockwise animation in career section */
.conic-gradient {
  background: conic-gradient(from 90deg, transparent, rgba(0, 0, 0, 0.2));
  animation: clockwise 4s linear infinite;
  width: 150%;
  height: 250%;
  top: -75% !important;
  left: -25% !important;
}
@keyframes clockwise {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* ..........about us slick-dot............... */

.aboutDots .slick-dots {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative !important;
  list-style: none !important;
}

.aboutDots .slick-dots li button:before {
  content: '' !important;
  z-index: 1 !important;
  list-style: none !important;
}

.aboutDots .slick-dots li button {
  background-color: gray;
  border-radius: 12px;
  width: 33px !important;
  height: 8px !important;
  list-style: none !important;
}
.aboutDots .slick-dots li.slick-active button {
  background-color: #050505;
  border-radius: 12px;
  width: 49px !important;
  height: 8px;
  z-index: 12 !important;
  position: relative;
  left: -8px;
  list-style: none !important;
}

/* text shadow in introhome section in slider */
.text-shadow {
  text-shadow: 1px 1px 4px white;
}

/* custom quote css */
.page-flip {
  position: fixed;
  z-index: 50;
  top: 100%;
  left: 20%;
  width: 100%;
  height: 100%;
  perspective: 1000px;
  rotate: 90deg;
}
.page {
  position: absolute;
  width: 200px;
  height: 250px;
  text-align: center;
}
.page-flip .page {
  transform-origin: left center;
  backface-visibility: hidden;
  transition: 2s;
  z-index: 1;
}

.page-flip .page.active {
  animation: flipPage 2s;
  z-index: 1;
}

.flip-page {
  transform: rotateX(360deg);
}

.page-flip .page:nth-child(3) {
  z-index: 2;
}
@keyframes flipPage {
  50% {
    transform: translateY(20px);
  }
}

/* vsync bg animation */

.rotation {
  perspective: 150px;
  animation: rotateAndScale 30s infinite linear;
}

@keyframes rotateAndScale {
  0% {
    transform: rotate(0deg) scale(0.9);
  }

  25% {
    transform: rotate(90deg) scale(0.7);
  }

  50% {
    transform: rotate(180deg) scale(1);
  }

  75% {
    transform: rotate(270deg) scale(0.7);
  }

  100% {
    transform: rotate(360deg) scale(0.9);
  }
}

.digitalBackground {
  background-image: url(./assets/digitalSign/digitalSignImage.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
